const quotesData = [
  {
    heading: 'Really nice people',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas sodales aliquam justo, ut molestie elit nisl risus. Dignissim turpis neque eget in ante pulvinar risus donec. Venenatis pulvinar dolor arcu arcu, lorem adipiscing sed. Volutpat, vitae fusce facilisi tempus donec alique',
    author: 'Sarah Jones, Leeds',
    image: {
      asset: {
        fixed: {
          src: 'https://cdn.sanity.io/images/3nbpuff1/production/739bd880bcc8f56172048b2dfbebca604245a5cf-40x40.jpg',
        },
      },
    },
  },
  {
    heading: 'Really nice people',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas sodales aliquam justo, ut molestie elit nisl risus. Dignissim turpis neque eget in ante pulvinar risus donec. Venenatis pulvinar dolor arcu arcu, lorem adipiscing sed. Volutpat, vitae fusce facilisi tempus donec alique',
    author: 'Sarah Jones, Leeds',
    image: {
      asset: {
        fixed: {
          src: 'https://cdn.sanity.io/images/3nbpuff1/production/739bd880bcc8f56172048b2dfbebca604245a5cf-40x40.jpg',
        },
      },
    },
  },
  {
    heading: 'Really nice people',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas sodales aliquam justo, ut molestie elit nisl risus. Dignissim turpis neque eget in ante pulvinar risus donec. Venenatis pulvinar dolor arcu arcu, lorem adipiscing sed. Volutpat, vitae fusce facilisi tempus donec alique',
    author: 'Sarah Jones, Leeds',
    image: {
      asset: {
        fixed: {
          src: 'https://cdn.sanity.io/images/3nbpuff1/production/739bd880bcc8f56172048b2dfbebca604245a5cf-40x40.jpg',
        },
      },
    },
  },
  {
    heading: 'Really nice people',
    quote:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas sodales aliquam justo, ut molestie elit nisl risus. Dignissim turpis neque eget in ante pulvinar risus donec. Venenatis pulvinar dolor arcu arcu, lorem adipiscing sed. Volutpat, vitae fusce facilisi tempus donec alique',
    author: 'Sarah Jones, Leeds',
    image: {
      asset: {
        fixed: {
          src: 'https://cdn.sanity.io/images/3nbpuff1/production/739bd880bcc8f56172048b2dfbebca604245a5cf-40x40.jpg',
        },
      },
    },
  },
]

export default quotesData
